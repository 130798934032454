export function dynamicMenu(): void {

    const element = document.querySelector('body');
    const header = document.querySelector('header');
    let headerHeight = header!.offsetHeight;
    const logo = document.querySelector('.logo') as HTMLElement;

    const logoWidth = logo.offsetWidth;
    const logoHeight = logo.offsetHeight;
    const ratio = logoWidth / logoHeight;
    const padding = logo.dataset.padding;
    const logoWidthSticky = logo.dataset.logoSticky;
    const logoStickyHeight = Number(logoWidthSticky) / ratio;

    let headerTopValue = ( (logoHeight - logoStickyHeight) ) * -1;
    let position = window.scrollY;

    let mql = window.matchMedia("(min-width: 768px)");

    function setStyles() {
        header!.style.height = headerHeight + (Number(padding) * 2) + "px";
        header!.style.paddingTop = padding + "px";
        header!.style.paddingBottom = padding + "px";
        document.addEventListener('scroll', function(event) {
            documentScrollHandler();
        });
        documentScrollHandler();
    }

    function resetStyles() {
        header!.style.height = "";
        header!.style.paddingTop = "";
        header!.style!.paddingBottom = "";

        document.removeEventListener('scroll', function(event) {
            documentScrollHandler();
        });
    }

    window.addEventListener("resize", function() {
        if (mql.matches) {
            setStyles();
        }
        else {
            resetStyles();
        }
    });

    if (mql.matches) {
        setStyles();
    }


    function documentScrollHandler() {
        var scrollY = window.scrollY;

        var headerDiff = headerHeight - ( document.querySelector('header').offsetHeight );
        var scrollYMax = scrollY + headerDiff;

        if (!mql.matches)
            return;
        
        if ( (scrollYMax) > headerHeight) {
            element.classList.add("menu-sticky");
            header.style.top = headerTopValue + "px";

            if (scrollY > position) {
                element.classList.add('down');
                element.classList.remove('up');
            } 
            else {
                element.classList.add('up');
                element.classList.remove('down');
            }
            position = scrollY;
        }
        else {
            element.classList.remove("menu-sticky");
            element.classList.remove('up');
            element.classList.remove('down');
            header.style.top = "0";
        }
        
    }

};


