export function validateForm(): void {

	const ffForm = <HTMLFormElement>document.querySelector(".ff-form");
	if (!ffForm) return;
	const submitButton = ffForm.querySelector(".btn-submit");
	
	submitButton!.addEventListener('click', function(e) {
		e.preventDefault();
		ffForm.classList.add("validation");
		if (ffForm.checkValidity()) {
			formFaktenSubmit();
		}
	})

	function formFaktenSubmit() {
		submitButton!.innerHTML = "Bitte warten";
		var formObj = {};
		formObj["modelAddress"] = window.location.toString();
		formObj["modelFFLangCookie"] = getCookie("FFLangCookie");
		var formData = serializeArray(ffForm);
		for (let i = 0; i < formData.length; i++) {
			var item = formData[i];
			formObj[item.name] = item.value;
		}
		const postData = JSON.stringify(formObj);
		const xhr = new XMLHttpRequest();

		xhr.onreadystatechange = function () {
			if (xhr.readyState == 4) { // 4 = done
				var data = JSON.parse(this.responseText);
				if (data.redirect) {
					window.location = data.redirect;
				}
				if (data.html) {
					ffForm.innerHTML = data.html;
				}
			}
		}
		xhr.open("POST", "/umbraco/api/formular/submit");
		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
		xhr.send(postData);
	}
	function getCookie(cookieName) {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var arr = cookies[i].split('=');
			if (arr.length < 2)
				return "";
			var name = arr[0].trim();
			var value = arr[1] || arr[1].trim();
			if (name === cookieName) {
				return value;
			} else if (value === cookieName) {
				return name;
			}
		}
		return "";
	}
	function serializeArray(form) {
		var field, l, s = [];
		if (typeof form == 'object' && form.nodeName == "FORM") {
			var len = form.elements.length;
			for (var i = 0; i < len; i++) {
				field = form.elements[i];
				if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
					if (field.type == 'select-multiple') {
						l = form.elements[i].options.length;
						for (var j = 0; j < l; j++) {
							if (field.options[j].selected)
								s[s.length] = { name: field.name, value: field.options[j].value };
						}
					} else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
						s[s.length] = { name: field.name, value: field.value };
					}
				}
			}
		}
		return s;
	}
};