export function dialog(): void {

    const dialog = document.querySelector("dialog");
    if (!dialog) return;
 
    const closeButton = document.querySelector("dialog button");
    const key = "hpdialog"; //dialog!.dataset.key || 'nokey';


    if (document.cookie.indexOf( key + '=1' ) != -1) {
        dialog.close();
    }
    else {
        dialog.showModal();
    }
    
    closeButton!.addEventListener("click", () => {
        var d = new Date();
        d.setTime(d.getTime() + (3600 * 1000 * 24 * 365));
        var expires = d.toUTCString();
        document.cookie = key + '=1;path=/;expires=' + expires;
        dialog.close();
    });

};


