export function animation(): void {

    const numSteps = 20.0;

    let boxElements;
    let prevRatio = 0.0;
    let increasingColor = "rgb(40 40 190 / ratio)";
    let decreasingColor = "rgb(190 40 40 / ratio)";
    
    // Set things up
    window.addEventListener(
      "load",
      (event) => {
        boxElements = document.querySelectorAll(".ff-block");
    
        createObserver();
      },
      false,
    );
    
    function createObserver() {
      let observer;
    
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: buildThresholdList(),
      };
    
      observer = new IntersectionObserver(handleIntersect, options);
      boxElements.forEach(boxElement => {
        observer.observe(boxElement);
      });
    }
    
    function buildThresholdList() {
      let thresholds = [];
      let numSteps = 20;
    
      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }
    
      thresholds.push(0);
      return thresholds;
    }
    
    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        let isCompleteInViewport = entry.target.offsetHeight < window.innerHeight
        let opacity = isCompleteInViewport ? entry.intersectionRatio : 1;
        entry.target.style.opacity = opacity;
/*        if (entry.intersectionRatio > prevRatio) {
            entry.target.style.width = (entry.intersectionRatio * 100) + "%";
        }
*/
        prevRatio = entry.intersectionRatio;
      });
    }


};