
export function footerAccordion(): void {

    const footerGrid = document.querySelector('.footer-grid');
    if (!footerGrid) return;

    let mql = window.matchMedia("(max-width: 768px)");
    if (mql) {

        const footerItems = footerGrid.querySelectorAll(".footer-items");
        window.addEventListener('resize', function() {
            footerItems.forEach(function(item) {
                item.classList.add("hidden");
            });
        });	
        footerItems.forEach(function(item) {
            item.classList.add("hidden");
            const head = item.querySelector("h5");
            if (head != null) {
                head.addEventListener('click', function(e) {
                    e.preventDefault();
                    let hidden = head.closest(".footer-items");
                    hidden.classList.toggle("hidden");
                });
            }
        });
    }
};
