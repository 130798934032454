
export function navInit(): void {
	const navbar = document.querySelector<HTMLElement>(".ff-navbar");
	if (!navbar) return;

	const subnavContainer = navbar.querySelectorAll(".subnav-container");
	const mainWrapper = navbar.querySelector<HTMLElement>(".main-nav-wrapper");
	const body = document.querySelector("body");
	const openMobileMenu = navbar.querySelector(".open-mobile-menu");
	//const mobileMenuClose = navbar.querySelectorAll(".mobile-menu-close");
	const subMenu = navbar.querySelectorAll(".ff-navbar-nav > .nav-item .mobile-arrow");
	const subnavBack = navbar.querySelectorAll(".mobile-tools .back");

	const navbarHeight = navbar.offsetHeight;
	mainWrapper!.style.top = navbarHeight + "px";

	function removeClasses() {
		mainWrapper!.classList.remove("visible");
		subnavContainer.forEach(function(item) {
			item.classList.remove("clicked");
			item.classList.remove("visible");
		});
		body!.classList.remove('navbar-open');
	}

	function openSubMenu(thisSubnav) {
		thisSubnav.classList.add("visible");
	}

	function closeSubMenu(thisSubnav) {
		thisSubnav.classList.remove("visible");
	}

	window.addEventListener('resize', function() {
		removeClasses();
	});

	openMobileMenu!.addEventListener('click', function(e){
		e.preventDefault();
		if (!mainWrapper!.classList.contains("visible")) {
			openMobileMenu!.setAttribute("aria-expanded", "true");
			mainWrapper!.classList.add("visible");
			body!.classList.add("navbar-open");
		}
		else {
			openMobileMenu!.setAttribute("aria-expanded", "false");
			removeClasses();
		}
	});

	subMenu.forEach(function(item) {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			var sibling = this.parentNode.querySelector(".subnav-container");
			openSubMenu(sibling);
		});
	});

	subnavBack.forEach(function(item) {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			var parent = this.closest(".subnav-container");
			closeSubMenu(parent);
		});
	});
};
