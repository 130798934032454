import { navInit } from "./components/navigations/navigations";
import { footerAccordion } from "./components/footer/footer";
import { dynamicMenu } from "./components/navigations/dynamicMenu";
import { validateForm } from "./components/forms/forms";
import { animation } from "./components/animation/animation";
import { dialog } from "./components/dialog/dialog";
/*import { loadMasonryImages } from "./scripts/masonry-load";
import { masonryInit } from "./scripts/masonry-init";
import { ffProjekte } from "./scripts/ff-projekte";
import { countdownInit } from "./scripts/count-down";*/
import PhotoSwipeLightbox from 'photoswipe/lightbox';


// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

// https://github.com/nicolafranchini/VenoBox/tree/master Alternative zu photoswipe

window.addEventListener('DOMContentLoaded', function() {
	var selector = '.pswp-gallery';	
	if (document.querySelector( selector )){
		console.log('selector found');
		const lightbox = new PhotoSwipeLightbox({
			gallery: selector,
			children: 'a',
			pswpModule: () => import('photoswipe')
		});
		lightbox.init();
	}
});	

// Wird das gebraucht? Von wem?
export const parseQueryString = function( queryString: string ): unknown {
	return JSON.parse( '{"' + decodeURI( queryString )
		.replace( /"/g, '\\"' )
		.replace( /&/g, '","' )
		.replace( /=/g, '":"' ) + '"}' );
};

window.addEventListener('load',function() {
	navInit();
	footerAccordion();
	dynamicMenu();
	validateForm();
	dialog();
});
animation();


/*
const closeMessage = (function() {

	const headerMessage = document.getElementById('header-message');
	if (!headerMessage) return;
	const hidden = sessionStorage.getItem("headerMessage");
	if (hidden !== "hidden") {
		headerMessage.classList.add("show");
	}
	const closeButton = headerMessage.querySelector('.close');
	closeButton.addEventListener("click", function(event) {
		event.preventDefault();
        event.stopPropagation();
		headerMessage.classList.remove("show");
		sessionStorage.setItem("headerMessage", "hidden");
	});
})();


function ResponsiveCellHeaders(tag, num) {
  try {
    var THarray = [];
    var table = tag;
	table.classList.add("table-" + num);
	var myClass = table.className;
    var ths = table.querySelectorAll("thead td");
    for (var i = 0; i < ths.length; i++) {
      var headingText = ths[i].innerHTML;
      THarray.push(headingText);
    }
    var styleElm = document.createElement("style"),
      styleSheet;
    document.head.appendChild(styleElm);
    styleSheet = styleElm.sheet;
    for (var i = 0; i < THarray.length; i++) {
      styleSheet.insertRule(
		  "." + myClass +
          " tbody td:nth-child(" +
          (i + 1) +
          ')::before {content:"' +
          THarray[i] +
          ' ";}',
        styleSheet.cssRules.length
      );
    }
  } catch (e) {
    console.log("ResponsiveCellHeaders(): " + e);
  }
}


(function initResponsiveTables() {
    const tables = document.getElementsByTagName("table");
	for (var i = 0; i < tables.length; i++) {
		ResponsiveCellHeaders(tables[i], i);
	}
})();

(function flipCard() {
    const cards = document.getElementsByClassName("flip-card");
	for (var i = 0; i < cards.length; i++) {
		const flipButton = cards[i].querySelector('.btn-flip');
		const card = cards[i];
		if(flipButton) {
			flipButton.addEventListener("click", (event) => {
				clickToFlip(event, card);
			});
		}
		const flipBack = cards[i].querySelector('.btn-flip-back');
		flipBack.addEventListener("click", (event) => {
			clickToFlip(event, card);
		});
	}
	function clickToFlip(event, card) {
		event.preventDefault();
		event.stopPropagation();
		if (card.classList.contains("flip")) {
			card.classList.remove("flip");
		}
		else {
			card.classList.add("flip");
		}
	}
})();

(function  slidePerClick() {


	const swiper = document.querySelectorAll('.swiper');
	if (!swiper) return;

	swiper.forEach(function(item) {
		const swiperContainer = item.querySelector(".swiper-container");
		const swiperWrapper = item.querySelector(".swiper-wrapper");
		const swiperWidth =  swiperWrapper.clientWidth;
		const swiperSlides = swiperWrapper.querySelectorAll(".swiper-slide");
		const slideCount = swiperSlides.length;
		const distance = swiperWidth / slideCount;

		const nextBtn = item.querySelector(".next");
		const prevBtn = item.querySelector(".prev");

		nextBtn.addEventListener('click', function(e) {
			e.preventDefault();
			sideScroll(swiperContainer,'right',distance);
		});
		prevBtn.addEventListener('click', function(e) {
			e.preventDefault();
			sideScroll(swiperContainer,'left',distance);
		});

		const slider = swiperContainer as HTMLElement;

		let isDown = false;
		let startX;
		let scrollLeft;
	
		
		const end = () => {
			isDown = false;
		  slider.classList.remove('active');
		}
		
		const start = (e) => {
		  isDown = true;
		  slider.classList.add('active');
		  startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
		  scrollLeft = slider.scrollLeft;	
		}
		
		const move = (e) => {
			if(!isDown) return;
		
		  e.preventDefault();
		  const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
		  const dist = (x - startX);
		  slider.scrollLeft = scrollLeft - dist;
		}
		
		slider.addEventListener('mousedown', start);
		slider.addEventListener('touchstart', start);
	
		slider.addEventListener('mousemove', move);
		slider.addEventListener('touchmove', move);
	
		slider.addEventListener('mouseleave', end);
		slider.addEventListener('mouseup', end);
		slider.addEventListener('touchend', end);
				


	})
	
	function sideScroll(element,direction,distance){
		if(direction == 'left'){	
			element.scrollBy({ 
				top: 0,
				left: distance * -1, 
				behavior: 'smooth' 
			});
		}
		if(direction == 'right'){	
			element.scrollBy({ 
				top: 0,
				left: distance, 
				behavior: 'smooth' 
			});
		}
	}

})();




*/

